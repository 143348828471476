export { createRequest, getReturnUrl, getComponentFromName, getTitle, validateEmail, validatePhone, loadReCaptcha, MultiFactorStates, Actions, getQueryParam, hasQueryParam, coerceToBase64Url, coerceToArrayBuffer, serviceNames, serviceInputNames }
import SqlIdentity from 'o365.vue.components.Login.Login.SqlIdentity.vue';
import ExternalLogin from 'o365.vue.components.Login.Login.External.vue';
import Fido2 from 'o365.vue.components.Login.Login.Fido2.vue';
import Login from 'o365.vue.components.Login.Login.Tabs.vue';
import MfaSelect from 'o365.vue.components.Login.Mfa.Select.vue';
import MfaVerify from 'o365.vue.components.Login.Mfa.Verify.vue';
import MfaRegister from 'o365.vue.components.Login.Mfa.Register.vue';
import Signup from 'o365.vue.components.Login.Login.Signup.vue';
import Sms from 'o365.vue.components.Login.Login.Sms.vue';

function createRequest(uri, data) {
    return fetch(uri, {
        method: 'POST', cache: 'no-cache',
        credentials: 'same-origin',
        headers: prepareHeaders(data),
        body: prepareData(data)
    });
}

function prepareHeaders(data){
    var headers = { 'Accept': 'application/json' };
    if(!data || typeof data === 'object' && data.constructor.name !== 'FormData') {
        headers['Content-Type'] = 'application/json';
    }
    return headers;
}

function prepareData(data){
    if(!data) return null;
    if(typeof data === 'object' && data.constructor.name === 'FormData') {
        return data;
    }    
    return JSON.stringify(data);
}

function getReturnUrl(){
    if(window.location.pathname.endsWith('/login') || window.location.pathname.endsWith('/login/mfa')){
        var returnUrl = getQueryParam('returnUrl')?.length > 0 ? getQueryParam('returnUrl') : '/';
        if(isAbsoluteUrl(returnUrl)){
            returnUrl = '/';
        }
        return returnUrl;
    } else {
        return window.location.pathname + window.location.search;
    }
}

function isAbsoluteUrl(url){
    try {
        var url = new URL(url);
        return true;
    } catch{
    }
    return false;
}

function getComponentFromName(vueName) {
    switch (vueName) {
        case 'sqlIdentity': return SqlIdentity;
        case 'saml2':
        case 'wsFederation':
        case 'openIdConnect':
        case 'standartOpenIdConnect':
        case 'standardOpenIdConnect':   
        case 'standartOpenIdConnect2':
        case 'standardOpenIdConnect2': return ExternalLogin;
        case 'login': return Login;
        case 'mfa': return MfaSelect;
        case 'verify': return MfaVerify;
        case 'register': return MfaRegister;
        case 'signup': return Signup;
        case 'fido2': return Fido2;
        case 'smsToken': return Sms;
    }
}

function getTitle(name) {
    switch (name) {
        case 'sqlIdentity': return 'Omega 365';
        case 'openIdConnect': return 'Microsoft';
        case 'wsFederation': return 'ADFS';
        case 'saml2': return 'SAML2';
        case 'fido2': return 'Passkey';
        case 'smsToken': return 'SMS';
    }
}

function validateEmail(mail) {
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(mail)) 
    {
        return false;
    }
    return true;
}

function validatePhone(phone) {
    if (!/^(\+{0,})(\d{0,})([(]{1}\d{1,3}[)]{0,}){0,}(\s?\d+|\+\d{2,3}\s{1}\d+|\d+){1}[\s|-]?\d+([\s|-]?\d+){1,2}(\s){0,}$/gm.test(phone)) {
        return false;
    }
    return true;
}

function loadReCaptcha(reCaptchaSiteKey){
    console.log("Loading google recaptcha v3");
    if(!reCaptchaSiteKey || reCaptchaSiteKey.length == 0) return;
    if(typeof grecaptcha == 'object') return;
    var script = document.createElement("script");
    script.setAttribute("src", "https://www.google.com/recaptcha/api.js?render=" + reCaptchaSiteKey);
    script.async = true;
    script.defer = true;
    script.onload = function() {
        grecaptcha.ready(function(){
            console.log('recapcha ready!', grecaptcha);           
        });
    };
    document.head.appendChild(script);
}

function getQueryParam(paramName){
    const searchParams = new URLSearchParams(window.location.search);
    return Array.from(searchParams.entries()).map(arr => {
        arr[0] = arr[0].toLowerCase();
        return arr
    }).find(arr => arr[0] === paramName.toLowerCase())?.[1] ?? null;
}

function hasQueryParam(paramName){
    const searchParams = new URLSearchParams(window.location.search);
    return Array.from(searchParams.entries()).map(arr => {
        return arr[0].toLowerCase();
    }).includes(paramName.toLowerCase());
}

function coerceToArrayBuffer(thing, name) {
    if (typeof thing === "string") {
        // base64url to base64
        thing = thing.replace(/-/g, "+").replace(/_/g, "/");

        // base64 to Uint8Array
        var str = window.atob(thing);
        var bytes = new Uint8Array(str.length);
        for (var i = 0; i < str.length; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
        thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
        thing = thing.buffer;
    }

    // error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
        throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
    }

    return thing;
};


function coerceToBase64Url(thing) {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
        thing = Uint8Array.from(thing);
    }

    if (thing instanceof ArrayBuffer) {
        thing = new Uint8Array(thing);
    }

    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
        var str = "";
        var len = thing.byteLength;

        for (var i = 0; i < len; i++) {
            str += String.fromCharCode(thing[i]);
        }
        thing = window.btoa(str);
    }

    if (typeof thing !== "string") {
        throw new Error("could not coerce to string");
    }

    // base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

    return thing;
};

// states: 0 - login, 1 - register, 2 - verifyRequired, 3 - Verified
const MultiFactorStates = Object.freeze({
    Login: 0,
    Register: 1,
    Verify: 2,
    Verified: 3
});

const Actions = Object.freeze({
    Login: 'login',
    Reset: 'reset',
    Signup: 'signup',
    Mfa:'mfa'
});


const serviceNames = { 'sms': 'SMS', 'email': 'Email', 'otp': 'TOTP', 'app': 'TOTP', 'fido2': 'Passkeys' }
const serviceInputNames = { 'sms': 'Mobile No', 'email': 'Email Address', 'otp': 'TOTP', 'app': 'TOTP' }